import { useEffect, useRef, useState } from 'react';
import { Alert, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { AnswerBlock, ImageContainer, InfoText, SubText } from './Style';
import trash from '../../assets/images/trash.svg';
import uploadDocGreen from '../../assets/images/upload-doc-green.svg';
import FormGenerator from '../../pages/Form/FormGenerator';
import ViewUploadedDocs from './ViewUploadedDocs';

const FileList = styled.li`
  position: relative;
  padding-top: 10px;
  width: 100%;
  word-break: break-all;
  ${(props) => (props.inValid ? 'text-decoration: line-through;color: #FF4B00;' : '')};
`;

const FileInput = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    visibility: hidden;
    margin-bottom: 5px;
`;

const FileSelect = ({ answerKey, data, onChange, options, isMulti, required, disabled, question }) => {
    const fileInputRef = useRef(null);
    const [errors, setErrors] = useState({ fileSizeError: false, fileFormatError: false });

    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        console.log("data[answerKey]", data[answerKey]);
    }, []);

    const handleFileChange = (event) => {
        const files = validate([...(data[answerKey] || []), ...Array.from(event.target.files)]);
        onChange(answerKey, files, true);
    };

    const validate = (files) => {
        let totalFileSize = 0;
        let fileSizeErrorCount = 0;
        let fileFormatErrorCount = 0;
        const allowedExtensions = /(\.doc|\.docx|\.xlsx|\.pdf|\.jpeg|\.jpg|\.png)$/i;

        files = files.map((file) => {
            if (file.size > 8000000) {
                file.inValid = true;
                fileSizeErrorCount++;
            };
            if (!allowedExtensions.exec(file.name)) {
                file.inValidFormat = true;
                fileFormatErrorCount++;
            };
            totalFileSize += file.size;
            return file;
        });
        if (totalFileSize > 19999999) {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: true
            }));
        } else {
            setErrors(prevState => ({
                ...prevState,
                totalFileSizeError: false
            }));
        };

        setErrors(prevState => ({
            ...prevState,
            fileSizeError: fileSizeErrorCount ? true : false,
            fileFormatError: fileFormatErrorCount ? true : false
        }));

        return files;
    };

    const deleteFiles = (index, file) => {
        const tempFiles = [...(data[answerKey] || [])];
        tempFiles.splice(index, 1);
        const files = validate(tempFiles);
        onChange(answerKey, files, true);
        if (file.path) {
            setTimeout(() => {
                const ans = Array.isArray(data['deleted_files']) ? data['deleted_files'] : [] || [];
                ans.push(file.path);
                onChange("deleted_files", ans, false);
            }, 0);
        };
    };

    const selectDoc = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        };
    };

    const closeView = () => {
        setShowModal(false);
        setSelectedFile({});
    };

    return (
        <AnswerBlock className='col-lg-12'>
            <Col className='text-center dash-border py-3'>
                <span onClick={selectDoc} className="cursor-pointer">
                    <img src={uploadDocGreen} alt="" />
                    <SubText className="text-success mb-0">
                        Click to upload new document
                    </SubText>
                    <InfoText className="text-secondary mb-0" dangerouslySetInnerHTML={{ __html: question.info_text }} />
                    <FileInput
                        name='recordFiles'
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        multiple
                        type='file'
                        accept=".doc, .docx, .xlsx, .pdf, image/jpeg, image/png"
                        disabled={disabled}
                    />
                </span>
                {question.question_blocks &&
                    question.question_blocks.map((q_blocks, index) => (
                        <div key={index}>
                            {
                                q_blocks &&
                                q_blocks.question_block &&
                                q_blocks.question_block[index].question &&
                                q_blocks.question_block.map((q, index) => (
                                    <FormGenerator
                                        members={[]}
                                        data={data}
                                        key={index}
                                        question={q.question}
                                        onFormDataChange={onChange}
                                        disabled={disabled}
                                        onChangeOfQuestion={() => onChange(q)}
                                    />
                                ))}
                        </div>
                    ))}
            </Col>

            <ol>
                {data[answerKey]?.map((file, index) => (
                    <FileList key={index} inValid={file.inValid || file.inValidFormat}>
                        <span onClick={() => { setSelectedFile(file); setShowModal(true); }} className='cursor-pointer'> {file.name}</span>
                        <ImageContainer onClick={() => deleteFiles(index, file)} className='mx-4' src={trash} />
                    </FileList>
                ))}
            </ol>
            {errors.fileSizeError &&
                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                    <p className='w-100 mb-0'>
                        Some files' sizes are more than 8MB. Please select files that are smaller than 8MB.
                    </p>
                </Alert>
            }
            {errors.fileFormatError &&
                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                    <p className='w-100 mb-0'>
                        Please select files with the following types: .doc, .docx, .xlsx, .pdf, .jpg, or .png. Files of other types are not supported.
                    </p>
                </Alert>
            }
            {errors.apiError &&
                <Alert id='error-alert' className='mb-2 mx-3' style={{ 'maxWidth': '100%' }} variant="danger">
                    <p className='w-100 mb-0'>
                        Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.
                    </p>
                </Alert>
            }
            {
                errors.totalFileSizeError &&
                <Alert id='error-alert' className='mb-2 mx-3' style={{ 'maxWidth': '100%' }} variant="danger">
                    <p className='w-100 mb-0'>
                        Total file sizes should be less than 20 MB.
                    </p>
                </Alert>
            }
            {
                showModal && <ViewUploadedDocs file={selectedFile} closeView={closeView} />
            }

        </AnswerBlock>
    );
}

export default FileSelect;