import { AnswerBlock } from "./Style";
import FormGenerator from "../../pages/Form/FormGenerator";

const OnlyQuestion = ({ label, group: question_blocks, answer = {}, data = {}, onChange, required = false, disabled = false, members = [], equipments = [], questionType }) => (
    <>
        <div className='col-lg-12 p-2 '>
            <b dangerouslySetInnerHTML={{ __html: label }} />
        </div>
        <AnswerBlock>
            {
                question_blocks &&
                question_blocks.map((q_blocks, index) => (
                    <div key={index}>
                        {
                            q_blocks &&
                            q_blocks.question_block &&
                            q_blocks.question_block[index].question &&
                            q_blocks.question_block.map((q, index) => (
                                <FormGenerator
                                    members={members}
                                    data={data}
                                    key={index}
                                    equipments={equipments}
                                    question={q.question}
                                    onFormDataChange={onChange}
                                    disabled={disabled}
                                    questionNo={index + 1}
                                    questionType={questionType}
                                    onChangeOfQuestion={() => onChange(q)}
                                />
                            ))}
                    </div>
                ))
            }
        </AnswerBlock>
    </>
);

export default OnlyQuestion;