import React from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { AnswerBlock, CustomValidationBlock, ErrorContainer } from './Style';
import FormGenerator from '../../pages/Form/FormGenerator';

const RadioButtonGroup = ({ group = [], answer = {}, data = {}, onChange, required = false, disabled = false, members = [], equipments = [], questionType }) => {
    return (
        < AnswerBlock >
            <CustomValidationBlock>
                {
                    group.map((choice, index) => (
                        <ButtonGroup className='mx-1' key={index} toggle>
                            <ToggleButton
                                type="checkbox"
                                variant="outline-success"
                                name={answer.key}
                                value={choice.value}
                                checked={data[answer.key] == choice.value}
                                onChange={() => { !disabled ? onChange(answer.key, choice.value, questionType) : null }}
                                required={required}
                                disabled={disabled && data[answer.key] != choice.value}
                            >
                                {choice.value}
                            </ToggleButton>
                        </ButtonGroup>
                    ))
                }
                <input className='hidden_input' type="text" value={data[answer.key] || ''} name={answer.key} id="" required={required} onChange={() => { }} />
                <ErrorContainer type="invalid">Please select one of these option.</ErrorContainer>
            </CustomValidationBlock>
            {
                group.map((choice) => (
                    choice.question_blocks &&
                    choice.question_blocks.map((q_blocks, index) => (
                        <div key={index}>
                            {
                                q_blocks &&
                                q_blocks.question_block &&
                                q_blocks.question_block[index].question &&
                                data[answer.key] === choice.value &&
                                q_blocks.question_block.map((q, index) => (
                                    <FormGenerator
                                        members={members}
                                        equipments={equipments}
                                        data={data}
                                        key={index}
                                        question={q.question}
                                        onFormDataChange={onChange}
                                        disabled={disabled}
                                        questionType={questionType}
                                        onChangeOfQuestion={() => onChange(q)}
                                    />
                                ))}
                        </div>
                    ))
                ))
            }
        </AnswerBlock >
    )
};

export default RadioButtonGroup;
