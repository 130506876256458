import React from 'react';
import { Input } from '..';
import { AnswerBlock, ErrorContainer } from './Style';

const InputField = ({ answerKey, value, onChange, required, disabled, placeholder = 'Input text', questionType }) => {
    return (
        <AnswerBlock className='col-lg-12'>
            <Input
                name={answerKey}
                value={value}
                placeholder={placeholder}
                onChange={(e) => onChange(answerKey, e.target.value, questionType)}
                type='text'
                required={required}
                disable={disabled}
            />
            <ErrorContainer type="invalid">Required</ErrorContainer>
        </AnswerBlock>
    )
};

export default InputField;
