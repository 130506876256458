import React, { useState } from "react";
import { FormViewContainer } from './Style';
import { WhiteButton } from '../Administration/Style'
import { useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import { Button, Table } from "react-bootstrap";
import pencil from '../../assets/images/pencil.svg';
import jsPDF from "jspdf";
import "jspdf-autotable";

const ReportSummeryForm = () => {
    const location = useLocation();
    const history = useHistory();

    const form = location.state?.form || {};
    let formData = location.state?.formData || {};
    const user = location.state?.user || {};

    const [questions, setQuestions] = useState([]);
    const [editing, setEditing] = useState(null);
    const [loading, setLoading] = useState(true);
    const [updatedAnswers, setUpdatedAnswers] = useState({});
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [errors, setErrors] = useState({ apiError: false });

    useEffect(() => {
        if (form && form.question_blocks) {
            let updatedForm = populateFormWithData(form, formData);
            setQuestions(updatedForm?.question_blocks || []);
            setLoading(false);
        }
    }, [form, formData]);

    const handleEdit = (stepIndex, qIndex) => {
        setEditing({ stepIndex, qIndex });
    };

    const handleSave = (stepIndex, qIndex, updatedAnswer, key) => {
        const updatedQuestions = [...questions];
        updatedQuestions[stepIndex].question_block[qIndex].question.answer.value = updatedAnswer;
        setQuestions(updatedQuestions);
        setUpdatedAnswers(prev => ({
            ...prev,
            [key]: updatedAnswer,
        }));
    };

    function populateFormWithData(form, formData) {
        function traverseAndFill(questionBlocks) {
            questionBlocks.forEach(block => {
                if (block.question) {
                    let key = block.question.answer?.key;
                    if (key && formData.hasOwnProperty(key)) {
                        block.question.answer.value = formData[key];
                    }

                    if (block.question.group) {
                        block.question.group.forEach(option => {
                            if (option.value === "No" && option.question_blocks) {
                                option.question_blocks.forEach(subBlock => {
                                    subBlock.question_block.forEach(subQ => {
                                        let subKey = subQ.question.answer?.key;
                                        if (subKey && formData.hasOwnProperty(subKey)) {
                                            subQ.question.answer.value = formData[subKey];
                                        }
                                    });
                                });
                            }
                        });
                    }
                }
            });
        }

        form.question_blocks.forEach(section => {
            traverseAndFill(section.question_block);
        });

        return form;
    }

    const handleDownloadPDF = () => {
        const doc = new jsPDF();

        doc.setFontSize(16);
        doc.text("Report Summary", 14, 15);

        let yPosition = 25;

        questions.forEach((section, stepIndex) => {
            doc.setFontSize(14);
            doc.setTextColor(0, 0, 255);
            doc.text(`${stepIndex + 1}. ${form.sections?.[stepIndex]?.label || "Untitled Section"}`, 14, yPosition);
            yPosition += 8;

            const tableColumn = ["#", "Question", "Response", "Location/Comments", "Who is Responsible"];
            const tableRows = [];

            section.question_block.forEach((q, qIndex) => {
                let locationComment = "-";
                let responsiblePerson = "-";

                if (q.question.group) {
                    q.question.group.forEach(option => {
                        if (option.value === "No" && option.question_blocks) {
                            option.question_blocks.forEach(subBlock => {
                                subBlock.question_block.forEach(subQ => {
                                    if (subQ.question.label.includes("Locations / Comments")) {
                                        locationComment = subQ.question.answer?.value || "-";
                                    }
                                    if (subQ.question.label.includes("Who is Responsible")) {
                                        responsiblePerson = subQ.question.answer?.value || "-";
                                    }
                                });
                            });
                        }
                    });
                }

                tableRows.push([
                    qIndex + 1,
                    q.question.label,
                    q.question.answer?.value || "-",
                    locationComment,
                    responsiblePerson,
                ]);
            });

            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: yPosition,
            });

            yPosition = doc.lastAutoTable.finalY + 10;
        });

        doc.save("Report_Summary.pdf");
    };

    const saveOrUpdateRecord = async () => {
        try {
            setSaveInProgress(true);

            const recordData = {
                name: form.name,
                record_for: user._id,
                formData: { ...formData, ...updatedAnswers },
                formJson: form,
                isCompleted: true,
                deleted: false,
                location: user?.location?._id,
                isApproved: user?.isAdmin
            };

            const apiUrl = location.state?.incompleteRecord
                ? `/api/v2/record/${location.state.incompleteRecord}`
                : '/api/v2/record';

            const filesFormData = new FormData();
            filesFormData.append("recordData", JSON.stringify(recordData));

            await axios.post(apiUrl, filesFormData);

            setSaveInProgress(false);

            Swal.fire({
                icon: 'success',
                title: 'Record has been saved successfully',
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => history.push("/forms"));
        } catch (error) {
            setSaveInProgress(false);
            setErrors({ apiError: true });
            console.error(error);
            Swal.fire({
                icon: 'error',
                title: 'Failed to save record',
                text: 'An error occurred while saving the record.',
            });
        }
    };

    const handleMainSave = () => {
        saveOrUpdateRecord();
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <FormViewContainer>
            <div className="d-flex justify-content-between mx-2">
                <div>
                    <h2 className='my-0'>Report Summary</h2>
                    <p className='my-0'>Based on your responses, hazards may be present related to the following items. This report will be stored in Ocoord for reference but you may also download a copy to distribute to appropriate personnel to address the items.</p>
                </div>
                <WhiteButton type='button' onClick={() => { history.goBack(); }} width='100' className='my-0'>
                    Cancel
                </WhiteButton>
            </div>

            <div className="p-4">
                <Table className="w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 p-2">#</th>
                            <th className="border border-gray-300 p-2">Question</th>
                            <th className="border border-gray-300 p-2">Response</th>
                            <th className="border border-gray-300 p-2">Location/Comments</th>
                            <th className="border border-gray-300 p-2">Who is Responsible</th>
                            <th className="border border-gray-300 p-2">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {questions?.map((section, stepIndex) => (
                            <React.Fragment key={stepIndex}>
                                <tr className="bg-gray-100">
                                    <td colSpan="6" className="p-2 font-bold text-lg border border-gray-300">
                                        <strong>{stepIndex + 1}. {form.sections?.[stepIndex]?.label}</strong>
                                    </td>
                                </tr>

                                {section.question_block.map((q, qIndex) => {
                                    let locationComment = "-";
                                    let responsiblePerson = "-";
                                    let locationKey = "";
                                    let responsibleKey = "";

                                    if (q.question.group) {
                                        q.question.group.forEach(option => {
                                            if (option.value === "No" && option.question_blocks) {
                                                option.question_blocks.forEach(subBlock => {
                                                    subBlock.question_block.forEach(subQ => {
                                                        if (subQ.question.label.includes("Locations / Comments")) {
                                                            locationComment = subQ.question.answer?.value || "-";
                                                            locationKey = subQ.question.answer?.key;
                                                        }
                                                        if (subQ.question.label.includes("Who is Responsible")) {
                                                            responsiblePerson = subQ.question.answer?.value || "-";
                                                            responsibleKey = subQ.question.answer?.key;
                                                        }
                                                    });
                                                });
                                            }
                                        });
                                    }

                                    return (
                                        <tr key={qIndex} className="border border-gray-300">
                                            <td className="p-2 border border-gray-300">{qIndex + 1}</td>
                                            <td className="p-2 border border-gray-300">{q.question.label}</td>

                                            {/* Response Column */}
                                            <td className="p-2 border border-gray-300">
                                                {editing?.stepIndex === stepIndex && editing?.qIndex === qIndex ? (
                                                    <input
                                                        type="text"
                                                        className="border border-gray-300 p-1"
                                                        defaultValue={q.question.answer.value}
                                                        onChange={(e) =>
                                                            handleSave(stepIndex, qIndex, e.target.value, q.question.answer.key)
                                                        }
                                                    />
                                                ) : (
                                                    q.question.answer.value || "-"
                                                )}
                                            </td>

                                            {/* Location/Comments Column */}
                                            <td className="p-2 border border-gray-300">
                                                {editing?.stepIndex === stepIndex && editing?.qIndex === qIndex ? (
                                                    <input
                                                        type="text"
                                                        className="border border-gray-300 p-1"
                                                        defaultValue={locationComment}
                                                        onChange={(e) =>
                                                            handleSave(stepIndex, qIndex, e.target.value, locationKey)
                                                        }
                                                    />
                                                ) : (
                                                    locationComment
                                                )}
                                            </td>

                                            {/* Who is Responsible Column */}
                                            <td className="p-2 border border-gray-300">
                                                {editing?.stepIndex === stepIndex && editing?.qIndex === qIndex ? (
                                                    <input
                                                        type="text"
                                                        className="border border-gray-300 p-1"
                                                        defaultValue={responsiblePerson}
                                                        onChange={(e) =>
                                                            handleSave(stepIndex, qIndex, e.target.value, responsibleKey)
                                                        }
                                                    />
                                                ) : (
                                                    responsiblePerson
                                                )}
                                            </td>
                                            <td className="p-2 border border-gray-300">
                                                {editing?.stepIndex === stepIndex && editing?.qIndex === qIndex ? (
                                                    <Button
                                                        style={{
                                                            'background': 'rgba(79, 185, 115, 1)'
                                                        }}
                                                        onClick={() => setEditing(null)}
                                                    >
                                                        Save
                                                    </Button>
                                                ) : (
                                                    <img style={{ cursor: 'pointer' }} className='cursor-pointer mr-2' onClick={() => handleEdit(stepIndex, qIndex)} src={pencil} alt="Edit Arrow" />
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
            <div className="text-right p-4">
                <Button style={{
                    'background': 'rgba(79, 185, 115, 1)',
                    'marginRight': '10px'
                }}
                    onClick={handleDownloadPDF}
                >
                    Download PDF Copy
                </Button>
                <Button style={{
                    'background': 'rgba(79, 185, 115, 1)'
                }}
                    onClick={handleMainSave}
                >
                    Save and Close Report
                </Button>
            </div>
        </FormViewContainer>
    );
};



export default ReportSummeryForm;
