import { createGlobalStyle } from 'styled-components';
import HauoraRegular from '../src/assets/fonts/hauora/Hauora-Regular.ttf';
import HauoraBold from '../src/assets/fonts/hauora/Hauora-Bold.ttf';
import HauoraSemiBold from '../src/assets/fonts/hauora/Hauora-SemiBold.ttf';
import HauoraMedium from '../src/assets/fonts/hauora/Hauora-Medium.ttf';

import HausNeueMedium from '../src/assets/fonts/HausNeue/HausNeue-Medium.otf';
import HausNeueLight from '../src/assets/fonts/HausNeue/HausNeue-Light.otf';
import HausNeueRegular from '../src/assets/fonts/HausNeue/HausNeue-Regular.otf';
import 'font-awesome/css/font-awesome.min.css';

/**
 * @constant GlobalStyles
 */
const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueLight}") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueRegular}") format("opentype");
  font-weight: 500;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueMedium}") format("opentype");
  font-weight: 600;
}
@font-face {
  font-family: "Haus Neue";   /*Can be any text*/
  src: url("${HausNeueMedium}") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraRegular}") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraMedium}") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraBold}") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Hauora";   /*Can be any text*/
  src: url("${HauoraSemiBold}") format("truetype");
  font-weight: 600;
}


* {
  font-family: "Hauora";  
  box-sizing: border-box;
}
p {
   font-family: "Haus Neue";
}
a {
  font-family: "Hauora";
  text-decoration: none;
}

.trainingMaterialsInfoModel {
  .modal-body {
      font-size: 16px !important;
  }
}

html,
body {
  --key-color: #4FB973;
  --secondary-key-color: #7FE281;
  --dark-text-color: #343747;
  --light-text-color: #7E818F;
  --destructive-color: #EA4141;
  --background-color: #F3F5F9;
  --navy-color: ##343747;

  color: var(--dark-text-color);
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-left: 25px;
  height: 100%;
  width: 100%;
  position: relative;
  background: var(--background-color);
}

.dash-border{
      border: 2px dashed #EFEFEF;
      border-radius: 1rem;
}

.cursor-pointer {
    cursor: pointer !important;
}

.light-text {
  color: var(--light-text-color) !important;
}

.search-choice {
  display: none !important;
}


h2 {
  font-weight: 600;
  font-size: 25px;
  color: var(--dark-text-color);
  letter-spacing: 0;
  font-family: 'Haus Neue'
}

.empty-content-container h2 {
  color: var(--light-text-color);
  font-weight: 600;
  font-size: 18px;
}

h3 {
  font-weight: 600;
  font-size: 15px;
}

.body-light {
  font-size: 12px;
  color: var(--light-text-color);
}

.label-text {
  font-size: 11px;
  color: var(--light-text-color);
}
textarea {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  border: 0.5px solid #D3D3D3;
  border-radius: 4px;

  transition: border 0.15s linear, box-shadow 0.15s linear;
  -webkit-transition: border 0.15s linear, box-shadow 0.15s linear;
  -moz-transition: border 0.15s linear, box-shadow 0.15s linear;
}

input:focus,
.chosen-container-active.chosen-with-drop .chosen-single,
.chosen-container-active .chosen-choices,
textarea:focus {
  border: 0.5px solid var(--key-color);
  outline: none;
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(79, 185, 115, 0.30);
}

input.error {
  border: 0.5px solid var(--destructive-color);
}

input.error:focus {
  box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16), 0 0 0 2px rgba(235, 66, 66, 0.30);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a6a6a6;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a6a6a6;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a6a6a6;
}
textarea {
  resize: vertical;
  text-wrap: inherit;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px LightGray;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #D3D6DD;
  border-radius: 8px;
}

.spinner-modal {
  .modal-content {
      background: none;
      border: none;
  }
}

button {
  font-family: 'Hauora';
  font-weight: 500 !important;
}

.w-5{
  width:5% !important;
  word-break: break-word;
}
.w-10{
  width:10% !important;
  word-break: break-word;
}
.w-15{
  width:15% !important;
  word-break: break-word;
}
.w-20{
  width:20% !important;
  word-break: break-word;
}
.w-25{
  width:25% !important;
  word-break: break-word;
}

`;

export default GlobalStyles;
