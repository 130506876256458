import React from 'react';
import { AnswerBlock, ErrorContainer } from './Style';

const TextareaInput = ({ answerKey, value, onChange, required, disabled, questionType }) => (
    <AnswerBlock>
        <textarea
            name={answerKey}
            value={value}
            className='col-lg-12'
            rows='5'
            wrap='off'
            onChange={(e) => onChange(answerKey, e.target.value, questionType)}
            required={required}
            disabled={disabled}
        />
        <ErrorContainer type="invalid">Required</ErrorContainer>
    </AnswerBlock>
);

export default TextareaInput;
