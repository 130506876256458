import React from "react";
import Select from "react-select";
import { AnswerBlock, CustomValidationBlock, ErrorContainer } from "./Style";
import { DropDownStyle } from "../../helpers";
import { useSelector } from "react-redux";

const PpeSelect = ({
    answerKey,
    selectedValue = [],
    onChange,
    options = [],
    isMulti = true,
    required = false,
    disabled = false,
    questionType
}) => {
    const loginUser = useSelector((state) => state.user.currentUser);
    const optionsWithDefaultValue = options.map((option) => ({
        value: option.value ? option.value : option._id,
        label: option.label ? option.label : option.name,
    }));
    const onDropDownChange = (selectedOptions) => {
        if (isMulti) {
            console.log(selectedOptions)
            const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];
            onChange(answerKey, selectedValues, questionType);
        } else {
            onChange(answerKey, selectedOptions ? selectedOptions.value : "", questionType);
        }
    };
    const chooseValue = () => {
        if (isMulti) {
            console.log("🚀 ~ chooseValue ~ selectedValue:", selectedValue)
            return selectedValue.length > 0
                ? optionsWithDefaultValue.filter((option) => selectedValue.includes(option.value))
                : [];
        } else {
            return optionsWithDefaultValue.find((option) => option.value === selectedValue) || null;
        }
    };
    return (
        <AnswerBlock className="col-lg-12 p-0">
            <CustomValidationBlock>
                <Select
                    name={answerKey}
                    value={chooseValue()}
                    onChange={onDropDownChange}
                    options={optionsWithDefaultValue}
                    isMulti={isMulti}
                    menuPosition="fixed"
                    styles={DropDownStyle}
                    isDisabled={disabled ? disabled : !loginUser.isAdmin}
                />
                <input
                    className="hidden_input"
                    type="text"
                    value={isMulti ? JSON.stringify(selectedValue) : selectedValue}
                    name={answerKey}
                    required={required}
                    readOnly
                />
                <ErrorContainer type="invalid">Required</ErrorContainer>
            </CustomValidationBlock>
        </AnswerBlock>
    );
};

export default PpeSelect;
