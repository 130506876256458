import styled from 'styled-components';
import { Row, Col, Card } from 'react-bootstrap';

export const Label = styled.div`
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => (props.textColor ? props.textColor : '#343747')}
    margin-bottom: 0px;
    background-color: ${(props) => (props.textColor ? '' : '#F3F5F9')};
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
`;

export const SubQuestionLabel = styled(Label)`
    font-size: 16px;
    font-weight: 500;
    color: #343747;
    margin-bottom: 0px;
    background-color: #ffffff;
    padding: 0rem;
    display: block;
    align-items: center;
`;

export const SrNoCircle = styled.div`
    height: 25px;
    width: 25px;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    border-radius: 50%;
    background-color: #ffffff;
    text-align: center;
    vertical-align: middle;
`;

export const ContentHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #F3F5F9;
    margin: 1.5rem 1rem;
    padding: 1.5rem;
`;

export const FormViewContainer = styled.div`    
    width: 100%;
    height: 100%;
    border-radius:10px;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    input[type="radio"]{
        accent-color: green;
        height:20px;
        width:20px;
        cursor: pointer;
    }
`;

export const DetailsSection = styled(Row)`

`;

export const SubSectionContainer = styled(Col)`
    overflow-y: auto;
    max-height: 69vh;
`;

export const QuestionSectionContainer = styled(Col)`
    overflow-y: auto;
    height: 69vh;
`;

export const SubSectionCard = styled(Card)`
  margin-bottom: 1rem !important;
  background-color: ${(props) => (props.completed ? '#F3F9F5' : 'transparent')}; 
  color: ${(props) => (props.active ? '#4FB973' : '#676767')};
  border: 1px solid ${(props) => (props.active ? '#4FB973' : 'transparent')} !important;
  box-shadow: 0px 0px 2px 0px
    ${(props) =>
        props.active ? '#4FB973' : props.completed ? 'transparent' : '#676767'};
  font-weight: 500 !important;
  
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')} !important;
  
  ${(props) =>
        !props.disabled &&
        `
      &:hover {
        border: 1.5px solid #4FB973 !important;
        box-shadow: 0px 0px 2px 0px #4FB973 !important;
      }
  `};
`;


export const SubSectionCardBody = styled(Card.Body)`
    display: flex;
    align-items: center;
`;

export const ImageContainer = styled.img``;

export const FooterButtonSection = styled.div`
    display: flex;
    justify-content: end;
`;

export const FormListContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    border-radius:10px;

    .row {
            &:last-child {
            margin-bottom: 1rem;
            }
            @media screen and (max-device-width:580px) and (orientation: portrait) {
                width:89%;
            }
            @media screen and (max-device-width:580px) and (orientation: landscape) {
                width:89%;
            }
        }
`;

export const FormContainer = styled.div`
    overflow-y: auto;
    max-height:68vh;
`;