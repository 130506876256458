import styled from 'styled-components';
import { Row, Col, Card, Form } from 'react-bootstrap';
import downloadIcon from '../../assets/images/download.svg';
import navigateUrl from '../../assets/images/cross-arrow.svg';

export const Label = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: ${(props) => (props.textColor ? props.textColor : '#343747')}
    margin-bottom: 0px;
    background-color: ${(props) => (props.textColor ? '' : '#F3F5F9')};
    padding: 0.5rem 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
`;

export const SubQuestionLabel = styled(Label)`
    font-size: 16px;
    font-weight: 500;
    color: #343747;
    margin-bottom: 0px;
    background-color: #ffffff;
    padding: 0rem;
    display: flex;
    align-items: center;
`;

export const ChoiceLabel = styled.label`
    font-size: 16px;
    color: ${(props) => (props.checked ? '#ffffff' : '#676767')};
    background-color: ${(props) => (props.checked ? '#42A764' : '#F3F9F5')}; 
    padding: 0.4rem 0.6rem;
    border-radius: 6px;
    display: block;
`;
export const ChoiceLabelForCertify = styled.label`
    font-size: 16px;
    padding: 0.4rem 0.6rem;
    border-radius: 6px;
    border: 2px solid #D3D6DD;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: block;
`;
export const SrNoCircle = styled.div`
    height: 25px;
    width: 25px;
    display: inline-block;
    border-radius: 50%;
    background-color: #ffffff;
    color: #4FB973;
    text-align: center;
    vertical-align: middle;
`;

export const AnswerBlock = styled.div`
    margin: 0.5rem 0rem !important;
    padding: 0px !important;
`;

export const SubText = styled.p`
font-family: 'Hauora';
font-size: 12px;
font-weight: 600;
`;

export const DownloadLinkUrl = styled(AnswerBlock)`
    border-bottom: 1px solid #D3D6DD;
    margin-bottom: 1rem!important;

    .download_url {
        color: #42A764;
        position: relative;
        padding-left: 20px;

        &::before {
            content: url(${downloadIcon});
            position: absolute;
            left: 0;
            top: 40%;
            transform: translateY(-50%);
            transition: transform 0.3s ease, top 0.3s ease; /* Smooth animation for movement */
        }

        &:hover::before {
            right: 50%;
            transform: translateY(-50%) scale(1.1); /* You can also scale the icon slightly for effect */
        }

        &:focus {
            outline: none;
            border: none;
            -moz-outline-style: none;
        }
    }

    .navigate_url {
        color: #42A764;
        position: relative;
        padding-left: 20px;

        &::before {
            content: url(${navigateUrl});
            position: absolute;
            left: 0;
            top: 35%;
            transform: translateY(-50%);
            transition: transform 0.3s ease, top 0.3s ease;
        }

        &:hover::before {
            right: 50%;
            transform: translateY(-50%) scale(1.1);
        }

        &:focus {
            outline: none;
            border: none;
            -moz-outline-style: none;
        }
    }
`;

export const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight:600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
`;

export const CustomValidationBlock = styled.div`
.hidden_input {
    height: 0px;
    opacity: 0;
    position: absolute;
}
`;

export const FormViewContainer = styled.div`    
    width: 100%;
    height: 100%;
    border-radius:10px;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    input[type="radio"]{
        accent-color: green;
        height:20px;
        width:20px;
        cursor: pointer;
    }
`;

export const DetailsSection = styled(Row)`

`;

export const SubSectionContainer = styled(Col)`
    overflow-y: auto;
    max-height: 69vh;
`;

export const QuestionSectionContainer = styled(Col)`
    overflow-y: auto;
    height: 69vh;
`;

export const SubSectionCard = styled(Card)`
  margin-bottom: 1rem !important;
  background-color: ${(props) => (props.completed ? '#F3F9F5' : 'transparent')}; 
  color: ${(props) => (props.active ? '#4FB973' : '#676767')};
  border: 1px solid ${(props) => (props.active ? '#4FB973' : 'transparent')} !important;
  box-shadow: 0px 0px 2px 0px
    ${(props) =>
        props.active ? '#4FB973' : props.completed ? 'transparent' : '#676767'};
  font-weight: 500 !important;
  
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'not-allowed')} !important;
  
  ${(props) =>
        !props.disabled &&
        `
      &:hover {
        border: 1.5px solid #4FB973 !important;
        box-shadow: 0px 0px 2px 0px #4FB973 !important;
      }
  `};
`;


export const SubSectionCardBody = styled(Card.Body)`
    display: flex;
    align-items: center;
`;

export const ImageContainer = styled.img`
    cursor: pointer;
`;

export const FooterButtonSection = styled.div`
    display: flex;
    justify-content: end;
`;

export const FormListContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
    margin: 6.5rem 1rem 0rem 6.5rem !important;
    border-radius:10px;

    .row {
            &:last-child {
            margin-bottom: 1rem;
            }
            @media screen and (max-device-width:580px) and (orientation: portrait) {
                width:89%;
            }
            @media screen and (max-device-width:580px) and (orientation: landscape) {
                width:89%;
            }
        }
`;

export const FormContainer = styled(Row)`
    overflow-y: auto;
    max-height:68vh;
`;

export const InfoText = styled.p`
    color: #676767;
    font-size: 14px;
    padding: 5px;
    margin: 0px;
    p {
        margin: 0px;
    }
`;