import React from 'react';
import styled from 'styled-components';
import WhiteCheckIcon from '../assets/images/glyph-checkmark-white.svg';
import GreenCheckIcon from '../assets/images/glyph-checkmark-green.svg';

/**
 * Checkbox component
 * Reusable checkbox that adapts background and icon color based on the `variant` prop.
 * @component
 * @example
 * return (<Checkbox checked={true} variant="green" />)
 */
export const Checkbox = ({ className, checked, variant = 'green', ...props }) => {
  const checkIcon = variant === 'white' ? GreenCheckIcon : WhiteCheckIcon;

  return (
    <label>
      <CheckboxContainer className={className}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox
          variant={variant}
          disabled={props.disabled}
          checked={checked}
        >
          {checked && <img src={checkIcon} alt='check icon' />}
        </StyledCheckbox>
      </CheckboxContainer>
    </label>
  );
};

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 0.5px solid #b9bcca;
  background: ${({ checked, variant }) =>
    checked
      ? variant === 'white' ? '#f7f7f7' : '#4fb973'
      : '#f7f7f7'};
  border-radius: 3px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(79, 185, 115, 0.5);
  }

  img {
    height: 10px;
    width: 10px;
  }
`;
