
import ocoordIcon from '../../assets/images/logo-icon-flat.svg'
import styled from 'styled-components';

import React from 'react';
export const FormContainer = styled.div`    
        width: 100%;
        height: 100%;
        border-radius:10px;
        padding: 60px;
        z-index:0;
        justify-content: start;
        margin: 0 0rem 0 4rem;
        align-self: flex-start;
        align-items: flex-start;
        background-color:#fff;
        box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
        margin: 6.5rem 6.5rem 0rem 6.5rem !important;
        input[type="radio"]{
            accent-color: green;
            height:20px;
            width:20px;
            cursor: pointer;
        }
    `;
const CheckboxItem = styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    input {
      appearance: none;
      width: 18px;
      height: 18px;
      border: 0.5px solid #b9bcca;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 150ms;
      cursor: pointer;
      position: relative;
    }

    input[type="radio"] {
      border-radius: 50%;
    }

`;

const PhysicianFollowupForm = () => {
    return (

        <FormContainer >
            <div className='row m-0'>
                <div className='col-12 d-flex align-items-center p-0'>
                    <img src={ocoordIcon} alt='OCoord Logo' width='30' height='30' className='me-2' />
                    <h2 className='m-2'>Ocoord</h2>
                </div>
            </div>
            <h2 className='mt-5'><b>Exposed Employee Physician Followup Form</b></h2>
            <h5 className='mt-5'>If the employee was using a safety engineered device, please explain the failure
                of the device.</h5>
            <input className="w-100" />
            <h5 className='mt-4'>What treatment options were administered to the employee?</h5>
            <CheckboxItem>
                <input
                    type="checkbox"
                />
                Expanded PEP
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Immune Globulin
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Hyperimmune Globulin
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Tetanus/Diphtheria
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Hepatitis B vaccine
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Basic PEP
            </CheckboxItem>
            <CheckboxItem>
                <input type="checkbox" /> Other
            </CheckboxItem>
            <h5 className='mt-4'>What treatment options were administered to the employee?</h5>
            <div className='col-6'>
                <CheckboxItem>
                    <input type="radio" /> Yes
                </CheckboxItem>
            </div>
            <div className='col-6'>
                <CheckboxItem>
                    <input type="radio" /> No
                </CheckboxItem>
            </div>
            <h5 className='mt-4'>Please provide any follow-up information and comments, including drug reactions, reported illnesses, etc.</h5>
            <textarea className="w-100" rows={10} />
            <div className="d-flex justify-content-between align-items-center mt-4">
                <div className='col-6 p-0'>
                    <h5><b>Healthcare Professional Signature</b> ____________________</h5>
                </div>
                <div className='col-6'>
                    <h5><b>Date</b> ______________</h5>
                </div>
            </div>
            <h5 className='mt-4'>
                Please send a copy of all test results to the employee’s place of employment where
                this record will be kept for the length of the employee’s duration of employment
                plus 30 years.
            </h5>

        </FormContainer>
    )
}

export default PhysicianFollowupForm;