import React from 'react';
import { Checkbox } from '../../components';
import { AnswerBlock, ChoiceLabelForCertify, CustomValidationBlock, ErrorContainer } from './Style';
const CertifyCheckbox = ({ label, data, onChange, disabled = false }) => {
    const checkboxKey = "certify_agreement";

    return (
        <CustomValidationBlock className='mt-5'>
            <AnswerBlock>
                <ChoiceLabelForCertify className={disabled ? '' : 'cursor-pointer'} htmlFor={checkboxKey} >
                    <div className='row'>
                        <div className='col-1 p-3 text-center'>
                            <Checkbox
                                type='checkbox'
                                variant='green'
                                id={checkboxKey}
                                name={checkboxKey}
                                checked={!!data[checkboxKey]}
                                disabled={disabled}
                                onChange={() => onChange(checkboxKey, !data[checkboxKey])}
                            />
                        </div>
                        <div className='col-11 p-0 mt-3'>
                            <span dangerouslySetInnerHTML={{ __html: label }}></span>

                        </div>
                    </div>

                </ChoiceLabelForCertify>
            </AnswerBlock>
            <input
                className='hidden_input'
                type="text"
                value={data[checkboxKey] ? 'temp_val' : ''}
                name={checkboxKey}
                required
                onChange={() => { }}
            />
            <ErrorContainer type="invalid">You must certify this</ErrorContainer>
        </CustomValidationBlock>
    );
};

export default CertifyCheckbox;
