import React from 'react';
import { Input } from '../../components';
import { AnswerBlock, ErrorContainer } from './Style';
import moment from 'moment';

const DateInput = ({ answerKey, value, onChange, required, disabled, selectUptoToday }) => (
    <AnswerBlock className='col-lg-12'>
        <Input
            max={selectUptoToday && moment().format("YYYY-MM-DD")}
            value={value ? moment(value).format("YYYY-MM-DD") : ''}
            onChange={(e) => onChange(answerKey, moment(e.target.value).format("YYYY-MM-DD"))}
            type='date'
            required={required}
            disable={disabled}
        />
        <ErrorContainer type="invalid">Required</ErrorContainer>
    </AnswerBlock>
);

export default DateInput;
