export const apiUrl = (newPath, expression) => {
  return `${process.env.NODE_ENV === 'productionx' ? 'https://staging.ocoord.com' : ''
    }${newPath}`;
};
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const validateEmail = ((email) => emailRegex.test(email));

export const userIdPattern = /^[a-f0-9]{24}$/i;

export const associateVariableNamePolicyReview = "MSD_REPORT_CONTACT_NAME";

export const FORM_NAMES = {
  SLIP_TRIP_FALL: 'Slip Trip and Fall Form'
};

const objectIdRegex = /^[0-9a-fA-F]{24}$/;
export const isMongoObjectId = (value) => objectIdRegex.test(value);
export const formatTimeInHmm = (seconds) => {
  // Calculate hours and minutes
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let result = ''
  if (hours) result += hours + " h ";
  if (minutes) result += minutes + " min";
  return result;
};
export const formatTimeInMinAndSec = (seconds) => {
  // Calculate minutes and remaining seconds
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  let result = '';
  if (minutes) result += minutes + " min ";
  if (remainingSeconds) result += remainingSeconds.toFixed(2) + " sec";
  if (!result) return '0 sec';
  return result;
};
export const ADMIN_ROUTES = ['/sds/add', '/sds/requests', '/admin', '/admin/staff', '/admin/role-list', '/admin/role-view', '/admin/ppe-list', '/admin/ppe-view', '/admin/task', '/admin/location-list', '/admin/location-view', '/admin/subscription',]

export const roles = [
  { label: 'Medical', value: '0' },
  { label: 'Dental', value: '1' },
  { label: 'Veterinary', value: '2' },
  { label: 'Medical Laboratory', value: '3' }
];

export const states = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District of Columbia', value: 'DC' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' }
];

export const DropDownStyle = {
  option: (provided, { isDisabled, isFocused }) => ({
    ...provided,
    backgroundColor: isDisabled ? null : isFocused ? '#4fb973' : null,
    color: isFocused ? 'white' : 'black'
  }),
  control: (provided) => ({
    ...provided,
    'boxShadow': 'inset 0 1px 1px 0 rgba(0, 0, 0, 0.16)',
    'borderColor': '#b9bcca',
    'fontFamily': 'Hauora',
    'color': '#161616',
    ':hover': {
      'borderColor': '#4fb973'
    },
    ':active': {
      'borderColor': '#4fb973'
    }
  })
};

export const HealthRecordDetails = {
  id: 'uploadHealthDocumentForm',
  icon_id: 'UploadHealthDocumentForm',
  name: 'Upload Health Documents',
  description: 'This form typically includes fields for recording details about the incident, such as the date, time, and location of the incident, names and contact information of any involved parties.',
  sections: [
    {
      stepNumber: 1,
      label: 'Upload Health Documents'
    }
  ],
  question_blocks: [
    {}
  ]
};

export const getDateFormatAsPerBrowserLanguage = () => {
  const dateFormat = new Intl.DateTimeFormat(navigator.language, { dateStyle: "short" }).formatToParts();
  let formatString = "";
  for (const part of dateFormat) {
    if (part.type === "day") {
      formatString += "DD-";
    } else if (part.type === "month") {
      formatString += "MM-";
    } else if (part.type === "year") {
      formatString += "YYYY";
    };
  };
  return formatString;
};

export const TaskFrequency = [
  { value: '0', label: 'Weekly' },
  { value: '1', label: 'Monthly' },
  { value: '2', label: 'Quarterly' },
  { value: '3', label: 'Yearly' }
];