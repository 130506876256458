import { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Figure, Modal, Row } from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

import { ActionButtonContainer, CustomCard, CustomCardBody, FooterButtonsRow, IconContainer, ImageContainer, Label, OverLayContainer, QuestionSectionContainer, RecordDetailsContainer, RecordHeading, RecordSubHeading, StyledLabel, SubSectionCard, SubSectionCardBody, SubSectionContainer } from "./Style";

import trash from '../../assets/images/trash.svg';
import archive from '../../assets/images/archive.svg';
import CalenderGray from '../../assets/images/calendar.svg';
import UserIcon from '../../assets/images/user-icon.svg';
import BloodbornePathogenExposureIncidentForm from '../../assets/forms-icon/BloodbornePathogenExposureIncident.svg';
import QuickFormHepatitisBVaccineConsentAndDeclinationForm from '../../assets/forms-icon/QuickFormHepatitisBVaccineConsentAndDeclination.svg';
import ViolenceIncidentForm from '../../assets/forms-icon/violenceIncidentForm.svg';
import ExposureIncidentForm from '../../assets/forms-icon/exposureIncidentForm.svg';
import SharpsInjuryForm from '../../assets/forms-icon/sharpsInjuryForm.svg';
import ChemicalExposureForm from '../../assets/forms-icon/chemicalExposureForm.svg';
import SharpsDisposalEvaluationForm from '../../assets/forms-icon/eval_sharps_disposal.svg';
import HepatitisBVaccineConsentForm from '../../assets/forms-icon/hepatitisBVaccineConsentForm.svg';
import SharpsDisposalContainerEvaluationForm from '../../assets/forms-icon/sharpsDisposalContainerEvaluationForm.svg';
import ECRIsNeedleStickPreventionDeviceEvaluationForm from '../../assets/forms-icon/ECRIsNeedleStickPreventionDeviceEvaluationForm.svg';
import SafetyDentalSyringesForm from '../../assets/forms-icon/safetyDentalSyringesForm.svg';
import SlipTripandFallForm from '../../assets/forms-icon/SlipTripandFallForm.svg';
import QuarterlySafetyReportForm from '../../assets/forms-icon/quarterlySafetyReportForm.svg';
import NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom from '../../assets/forms-icon/needlestickSafetyAndPreventionAnnualRiskAssessment.svg';
import AnnualTBRiskAssessmentForm from '../../assets/forms-icon/tbRiskAssessment.svg';
import UploadHealthDocumentForm from '../../assets/forms-icon/uploadHealthDocumentForm.svg';
import ImmunizationRecordIcon from '../../assets/forms-icon/immunizationRecord.svg';
import ConsentOfVaccinationOrTestingIcon from '../../assets/forms-icon/consentOfVaccinationOrTesting.svg';
import DeclinationOfVaccinationOrTestingIcon from '../../assets/forms-icon/declinationOfVaccinationOrTesting.svg';

import OtherHealthRecordIcon from '../../assets/forms-icon/otherHealthRecordIcon.svg';
import CPRTrainingIcon from '../../assets/forms-icon/CPRTraining.svg';
import ContinuingEducationCreditsIcon from '../../assets/forms-icon/continuingEducationCredits.svg';
import SPICEOfficerCertificationIcon from '../../assets/forms-icon/SPICEOfficerCertification.svg';
import docListIcon from '../../assets/images/doc-list.svg';
import infoIcon from '../../assets/images/info.svg';
import FormGenerator from "../Form/FormGenerator";

import completed from '../../assets/forms-icon/circle-checked.svg';
import chevronRight from '../../assets/forms-icon/chevron-right.svg';
import closeIcon from '../../assets/images/glyph-close-gray.svg';


const icons = { ViolenceIncidentForm, SharpsDisposalContainerEvaluationForm, ExposureIncidentForm, SharpsInjuryForm, ChemicalExposureForm, SharpsDisposalEvaluationForm, HepatitisBVaccineConsentForm, ECRIsNeedleStickPreventionDeviceEvaluationForm, SafetyDentalSyringesForm, SlipTripandFallForm, QuarterlySafetyReportForm, NeedlestickSafetyAndPreventionAnnualRiskAssessmentFrom, AnnualTBRiskAssessmentForm, BloodbornePathogenExposureIncidentForm, QuickFormHepatitisBVaccineConsentAndDeclinationForm, UploadHealthDocumentForm, ImmunizationRecordIcon, ConsentOfVaccinationOrTestingIcon, DeclinationOfVaccinationOrTestingIcon, OtherHealthRecordIcon, CPRTrainingIcon, ContinuingEducationCreditsIcon, SPICEOfficerCertificationIcon, OtherEducationRecordIcon: OtherHealthRecordIcon };

const RecordDetails = ({ record, onCloseDetailsTab, forms, members, equipments, afterUpdate, deleteRecord, archiveRecord, recordType }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const [form, setForm] = useState({});
    const [questionBlock, setQuestionBlock] = useState([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [error, setError] = useState('');
    const [isShowDocOpen, setIsShowDocOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState({ url: '', type: '' });
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (record.completedForm) {
            record.formData = record.completedForm.formData;
            // const tempForm = forms.find(form => record.completedForm.formId === form.id);
            const tempForm = JSON.parse(record.completedForm.formJson);
            if (tempForm) setForm(tempForm);
        } else if (record && record.formId) {
            const tempForm = forms.find(form => record.formId === form.id);
            // const tempForm = JSON.parse(record?.formJson);
            if (tempForm) setForm(tempForm);
        };
    }, []);

    const showForm = (show) => {
        setFormOpen(show);
        if (show) {
            console.log(record._id)
            const data = JSON.parse(record?.formJson);
            const { question_block } = data.question_blocks[currentStep];
            setQuestionBlock(question_block);
        } else {
            setQuestionBlock([]);
        };
    };

    const changeStep = (stepNumber) => {
        const data = JSON.parse(record?.formJson);
        const { question_block } = data.question_blocks[stepNumber - 1];
        setQuestionBlock(question_block);
        setCurrentStep(stepNumber - 1);
    };

    const updateRecord = async (status) => {
        try {
            setError('');
            await axios.put(`/api/v2/update_record_status/${record._id}`, { status });
            Swal.fire({
                icon: 'success',
                title: `Record has been ${status} successfully`,
                allowEnterKey: true,
                allowEscapeKey: false,
                confirmButtonColor: '#4FB973',
                confirmButtonText: 'Close',
                allowOutsideClick: false
            }).then(() => {
                afterUpdate();
            });
        } catch (error) {
            setError("Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.");
        }
    };

    const viewOrDownloadDocument = async (file) => {
        try {
            const response = await axios.get(`/api/v1/records/files/${file._id}`, {
                responseType: 'blob',
            });
            const fileBlob = new Blob([response.data], { type: file.data.contentType });
            const url = URL.createObjectURL(fileBlob);
            const able_to_view_doc_types = ['application/pdf', 'image/png', 'image/jpeg'];
            if (able_to_view_doc_types.includes(file.data.contentType)) {
                setSelectedDoc({ url, type: file.data.contentType });
                setFileName(file?.data?.name);
                setIsShowDocOpen(true);
            } else {
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(fileBlob);
                link.download = file.data.name;
                setFileName(file?.data?.name);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            };
        } catch (error) {
            console.log("🚀 ~ viewOrDownloadDocument ~ error:", error)
        }
    };

    const getUser = (userId) => {
        const user = members.find(m => m._id === userId);
        return user?.fullName || '- -';
    };

    const docClose = (e) => {
        e.stopPropagation()
        setSelectedDoc({ url: '', type: '' });
        setIsShowDocOpen(false);
    };

    return (
        <OverLayContainer onClick={() => onCloseDetailsTab()}>
            <RecordDetailsContainer onClick={(e) => { e.stopPropagation() }} fullWidth={formOpen}>
                <ActionButtonContainer className='mx-0'>
                    <Col lg='6' md='6' sm='12' className="px-0">
                        <Button onClick={() => onCloseDetailsTab()} type='button' variant='light' className='text-success px-4'>
                            <strong>{'<< Back'}</strong>
                        </Button>
                    </Col>
                    {recordType === 'active' && currentUser.isAdmin && <Col lg='6' md='6' sm='12' className='text-right px-0'>
                        {record.isApproved &&
                            <Button type="button" onClick={(e) => archiveRecord(e, record)} variant="light" style={{ color: '#7E818F', fontWeight: '600' }}> <img src={archive} alt="Archive" className="mr-2" />
                                Archive
                            </Button>
                        }
                        <Button type="button" onClick={(e) => deleteRecord(e, record)} className="mx-2" variant="light" style={{ color: '#FF4B00', fontWeight: '600' }}><img src={trash} alt="Trash" className="mr-2" />
                            Delete
                        </Button>
                    </Col>
                    }
                </ActionButtonContainer>
                <Row className="my-4 mx-0">
                    <RecordHeading>{record.name}</RecordHeading>
                    <RecordSubHeading>{record.instructions}</RecordSubHeading>
                </Row>
                <Row className='d-flex align-items-center my-4 mx-0'>
                    <StyledLabel className="w-100">Completed On</StyledLabel>
                    <img src={CalenderGray} alt="" className='mr-3' /> <p className="mb-0" style={{ fontSize: '16px' }}>{moment(record.completedAt).format('MM/DD/YYYY hh:mm A') || '--'}
                    </p>
                </Row>
                <Row className='d-flex align-items-center my-4 mx-0'>
                    <StyledLabel className="w-100">Completed By</StyledLabel>
                    <img src={UserIcon} alt="" className='mr-3' /> <p className="mb-0" style={{ fontSize: '16px' }}>
                        {record.completedBy?.fullName}
                    </p>
                </Row>
                {(record.isApproved && record.approvedAt && record.approvedBy) ? <>
                    <Row className='d-flex align-items-center my-4 mx-0'>
                        <StyledLabel className="w-100">Approved On</StyledLabel>
                        <img src={CalenderGray} alt="" className='mr-3' /> <p className="mb-0" style={{ fontSize: '16px' }}>
                            {moment(record.approvedAt).format('MM/DD/YYYY hh:mm A') || '--'}
                        </p>
                    </Row>
                    <Row className='d-flex align-items-center my-4 mx-0'>
                        <StyledLabel className="w-100">Approved By</StyledLabel>
                        <img src={UserIcon} alt="" className='mr-3' /> <p className="mb-0" style={{ fontSize: '16px' }}>{getUser(record.approvedBy)}</p>
                    </Row>
                </>
                    : null
                }
                {record.files && record.files.length ?
                    <div className='my-5'>
                        <StyledLabel>Uploaded Documents</StyledLabel>
                        {record.files.map((file, index) =>
                            <CustomCard key={'file_' + index}>
                                <CustomCardBody className='d-flex' onClick={() => viewOrDownloadDocument(file)}>
                                    <IconContainer>
                                        <ImageContainer
                                            width='56'
                                            height='56'
                                            src={docListIcon}
                                        />
                                    </IconContainer>
                                    <div className="d-flex justify-content-between align-items-center w-100">
                                        <h5 style={{ wordBreak: 'break-all' }}>{file.data?.name}</h5> <img src={infoIcon} title='This information is only seen by you and your practice administrator.' />
                                    </div>
                                </CustomCardBody>
                            </CustomCard>)}
                    </div> :
                    !formOpen ?
                        <div className='my-5'>
                            {
                                (form && form.name) ?
                                    <>
                                        <StyledLabel>Completed Form</StyledLabel>
                                        <CustomCard onClick={() => showForm(true)}>
                                            <CustomCardBody>
                                                <IconContainer>
                                                    <ImageContainer
                                                        width='56'
                                                        height='56'
                                                        src={icons[form.icon_id]}
                                                    />
                                                    <Figure.Caption>
                                                        <div className="text-success">
                                                            <h5>{form.name}</h5>
                                                        </div>
                                                        <p>{form.description}</p>
                                                    </Figure.Caption>
                                                </IconContainer>
                                            </CustomCardBody>
                                        </CustomCard>
                                    </>
                                    : null
                            }
                        </div>
                        :
                        <Row className='mt-2 mx-0'>
                            <Col lg='12' md='12' sm='12' className="mb-2 px-0">
                                <StyledLabel>Completed Form</StyledLabel>
                            </Col>
                            <SubSectionContainer lg='4' md='12' sm='12'>
                                {form.sections && form.sections.map(({ label, stepNumber }) => (
                                    <SubSectionCard onClick={() => changeStep(stepNumber)} active={stepNumber === currentStep + 1 ? 'true' : ''} key={'label___' + stepNumber} >
                                        <SubSectionCardBody>
                                            <Col lg='1' className='p-0'>
                                                <ImageContainer src={stepNumber === currentStep + 1 ? completed : completed} />
                                            </Col>
                                            <Col lg='10' sm='10' className='p-0' >
                                                <Label textColor={stepNumber === currentStep + 1 ? '#4FB973' : '#343747'}>{stepNumber + ". " + label}</Label>
                                            </Col>
                                            <Col lg='1' className='p-0'>
                                                {stepNumber === currentStep + 1 && <ImageContainer src={chevronRight} />}
                                            </Col>
                                        </SubSectionCardBody>
                                    </SubSectionCard>
                                ))}
                            </SubSectionContainer>
                            <QuestionSectionContainer lg='8' md='12' sm='12'>
                                {questionBlock && questionBlock.map((question, index) => (
                                    <Col md='12' sm='12' lg='12' className="mb-3 px-0" key={'questionBlock___' + index}>
                                        <FormGenerator
                                            members={members}
                                            equipments={equipments}
                                            questionNo={index + 1}
                                            data={JSON.parse(record.formData)}
                                            question={question.question}
                                            disabled={true}
                                        />
                                    </Col>
                                ))}
                            </QuestionSectionContainer>
                        </Row>
                }
                {!record.isApproved && currentUser.isAdmin &&
                    <FooterButtonsRow>
                        <Col lg={formOpen ? 8 : 12} md='12' sm='12' className="d-flex justify-content-between">
                            <Button onClick={() => updateRecord('discarded')} variant="secondary" className="w-48">Discard</Button>
                            <Button onClick={() => updateRecord('approved')} variant="success" className="w-48">Approve</Button>
                        </Col>
                    </FooterButtonsRow>
                }
            </RecordDetailsContainer>
            <Modal
                centered
                animation={true}
                backdrop="static"
                size='xl'
                show={isShowDocOpen}>
                <Modal.Header>
                    <Modal.Title className="w-100">
                        <div className="d-flex justify-content-between">
                            <span> <b>Document:</b> {fileName} | <b>Uploaded:</b>
                                {moment(record.completedAt).format('MM/DD/YYYY hh:mm A') || '--'}
                            </span>
                            <span onClick={docClose} className="mr-2 cursor-pointer">
                                <img src={closeIcon} alt="" />
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {selectedDoc.type === 'application/pdf' ?
                            <iframe src={selectedDoc.url} frameborder="0" className="w-100" style={{ height: '75vh' }} />
                            :
                            <img src={selectedDoc.url} style={{ width: '100%' }} />}
                    </Container>
                </Modal.Body>
            </Modal>
        </OverLayContainer >
    )
}

export default RecordDetails;