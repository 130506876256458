import React, { useEffect } from 'react';
import Select from 'react-select';
import { AnswerBlock, CustomValidationBlock, ErrorContainer } from './Style';
import { DropDownStyle } from '../../helpers';
import { useSelector } from 'react-redux';

const EmployeeSelect = ({ answerKey, selectedValue, onChange, options, isMulti, required, disabled }) => {
    const loginUser = useSelector(state => state.user.currentUser);

    const onDropDownChange = (answerKey, e) => {
        if (isMulti) {
            const value = e.map(value => value.value);
            onChange(answerKey, value);
        } else {
            onChange(answerKey, e.value);
        }
    };

    const chooseValue = () => {
        if (isMulti) {
            return selectedValue && options.filter(value => selectedValue.includes(value.value)) || '';
        } else {
            return selectedValue && options.find(option => option.value === selectedValue) || '';

        };
    };

    return (
        <AnswerBlock className='col-lg-12 p-0'>
            <CustomValidationBlock>
                <Select
                    value={chooseValue()}
                    onChange={(e) => onDropDownChange(answerKey, e)}
                    options={options}
                    isMulti={isMulti}
                    menuPosition="fixed"
                    styles={DropDownStyle}
                    required={required}
                    isDisabled={disabled ? disabled : !loginUser.isAdmin}
                />
                <input className='hidden_input' type="text" value={chooseValue() || ''} name={answerKey} id="" required={required} onChange={() => { }} />
                <ErrorContainer type="invalid">Required</ErrorContainer>
            </CustomValidationBlock>
        </AnswerBlock>
    )
};

export default EmployeeSelect;
