import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';

import pending from '../../assets/forms-icon/circle-gray.svg';
import active from '../../assets/forms-icon/circle-green.svg';
import completed from '../../assets/forms-icon/circle-checked.svg';
import chevronRight from '../../assets/forms-icon/chevron-right.svg';

import { WhiteButton } from '../Administration/Style';
import { Button } from '../../components';
import FormGenerator from './FormGenerator';
import UploadDocuments from './UploadDoc';
import { HealthRecordDetails } from '../../helpers';
import { DetailsSection, FooterButtonSection, FormViewContainer, ImageContainer, Label, QuestionSectionContainer, SubSectionCard, SubSectionCardBody, SubSectionContainer } from './Style';
import { apiUrl } from '../../helpers/constants';
import { userIdPattern, associateVariableNamePolicyReview, FORM_NAMES } from '../../helpers/constants'


class FormView extends Component {
    state = {
        loading: true,
        form: {},
        filesFormData: new FormData(),
        formData: {},
        currentStep: 1,
        completedStep: 1,
        errors: { apiError: false },
        members: [],
        formId: '',
        taskId: '',
        isDataChanged: false,
        saveInprogress: false,
        equipments: [],
        showForm: false,
    };
    timeoutId = null;

    componentDidMount() {
        this.getForm();
        this.getMasterData();
        const { user } = this.props;
        if (!user.isAdmin) {
            this.setState({ record_for: user._id, formData: { record_for: user._id } });
        }
    };

    getIncompleteFormData = async (formId, taskId) => {
        try {
            const { user: { location } } = this.props;
            let apiUrl = `/api/v2/inCompletedRecords/${formId}/${location._id}`;
            if (taskId) apiUrl += `/${taskId}`;

            const { data: { payload } } = await axios.get(apiUrl);
            const formData = JSON.parse(payload.formData);

            //To do - Temporary fix for SlipTripandFallForm
            // if (formId === 'SlipTripandFallForm' && payload?.currentStep == 11) {
            //     payload.currentStep = 0;
            //     formData = {};
            // }

            this.setState({ loading: false, formData, currentStep: payload.currentStep + 1, completedStep: payload.currentStep + 1, incompleteRecord: payload._id, formId });
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, formId });
        }
    };

    handleOpenForm = (e) => {
        e.preventDefault();
        const form = e.target.closest("form");
        if (!form || !form.checkValidity()) {
            e.stopPropagation();
            this.setState({ validated: true });
        } else {
            this.setState({ validated: false }, () => {
                this.props.history.push("/report-summary", {
                    form: this.state.form,
                    formData: this.state.formData,
                    user: this.props.user
                });
            });
        }
    };

    findAnswerByVariable(data, variableName) {
        if (!data || !data.libraryData) {
            return null;
        }
        const foundItem = data?.libraryData?.find(item => item?.associatedVariable === variableName);
        return foundItem ? foundItem.answer : null;
    }

    updateMSDReportContact(form, contactName) {
        form?.question_blocks.forEach(block => {
            block?.question_block.forEach(questionItem => {
                if (questionItem.question.type === "contentful_text") {
                    questionItem.question.label = questionItem.question.label.replace(associateVariableNamePolicyReview, contactName);
                }
            });
        });
        return form;
    }

    getForm = async () => {
        try {
            this.setState({ loading: true });
            const { history: { location } } = this.props;
            const searchParams = new URLSearchParams(location.search);
            const formId = searchParams.get('formId');
            const taskId = searchParams.get('taskId');
            if (formId === 'uploadHealthDocumentForm') {
                this.setState({ loading: false, form: HealthRecordDetails, formId });
            } else if (formId === 'ergonomicsPolicyReviewAndAcknowledgement') {
                const { user: { location } } = this.props;
                const { data } = await axios.get(apiUrl(`/api/v2/libraryData/${location._id}`))
                let answer = this.findAnswerByVariable(data?.payload, associateVariableNamePolicyReview);
                if (userIdPattern.test(answer)) {
                    const { data: { payload } } = await axios.get(`/api/v2/user/${answer}`);
                    answer = payload?.firstName + " " + payload?.lastName;
                }
                const { data: { payload: form } } = await axios.get('/api/v2/form/' + formId);
                this.updateMSDReportContact(form, answer);
                this.setState({ form, formId, taskId });
                await this.getIncompleteFormData(formId, taskId);
            } else {
                const { data: { payload: form } } = await axios.get('/api/v2/form/' + formId);
                this.setState({ form, formId, taskId });
                await this.getIncompleteFormData(formId, taskId);
            }
        } catch (error) {
            console.error(error);
            this.setState({ loading: false, errors: { apiError: true, errorMessage: 'Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.' } });
            return {};
        }
    };

    getMasterData = async () => {
        try {
            const { user: { location } } = this.props;
            const { data: { payload: members } } = await axios.get(`/api/v2/master/users_by_location/${location._id}`);
            const { data: { payload: ppe } } = await axios.get(`/api/v2/master/safetyequipments/${location._id}`);
            this.setState({ members });
            this.setState({ equipments: ppe });
        } catch (error) {
            console.error(error);
        }
    };


    onModalChange = (key, value, questionType, is_file = false) => {
        const { formData, currentStep, completedStep, filesFormData } = this.state;
        if (is_file) {
            const tempFilesFromData = filesFormData;
            tempFilesFromData.delete(key);
            for (const file of value) {
                tempFilesFromData.append(`${key}`, file);
            };
            this.setState({ formData: { ...formData, [key]: value }, filesFormData: tempFilesFromData });
        } else if (questionType && questionType.type && questionType.type === 'button_to_add_fields_repeatedly') {
            if (typeof value === 'string') value = value.trimStart();
            this.setState((prevState) => {
                let formData = { ...prevState.formData };
                let keyData = Array.isArray(formData[questionType.blockKey]) ? [...formData[questionType.blockKey]] : [];
                if (questionType.blockIndex >= keyData.length) {
                    keyData.push({ [key]: value });
                } else {
                    keyData[questionType.blockIndex] = {
                        ...keyData[questionType.blockIndex],
                        [key]: value,
                    };
                }
                return { formData: { ...formData, [questionType.blockKey]: keyData } };
            });
        } else {
            if (typeof value === 'string') value = value.trimStart();
            if (key === 'record_name' || key === 'record_for') {
                this.setState({ [key]: value, formData: { ...formData, [key]: value } });
            } else {
                this.setState({ formData: { ...formData, [key]: value } });
            };
            if (currentStep < completedStep) {
                this.setState({ isDataChanged: true });
            };
        };
    };

    changeStep = (currentStep) => {
        const { completedStep, isDataChanged } = this.state;
        if (isDataChanged) {
            this.setState({ errors: { dataChangedError: true } });
            return;
        };
        if (currentStep <= completedStep && !isDataChanged) {
            this.setState({ currentStep });
        };
    };

    update = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ validated: true });
        } else {
            try {
                const { currentStep, completedStep, formId, taskId } = this.state;
                await this.saveOrUpdateRecord();
                await this.getIncompleteFormData(formId, taskId);
                const cs = (currentStep + 1) > completedStep ? currentStep + 1 : completedStep;
                this.setState({ currentStep: currentStep + 1, completedStep: cs, isDataChanged: false });
            } catch (error) {
                console.error(error);
            }
            this.setState({ validated: false });
        }
    };

    save = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ validated: true });
        } else {
            const res = await this.saveOrUpdateRecord();
            this.setState({ validated: false });
            if (res) {
                const { history } = this.props;
                Swal.fire({
                    icon: 'success',
                    title: 'Record has been created successfully',
                    allowEnterKey: true,
                    allowEscapeKey: false,
                    confirmButtonColor: '#4FB973',
                    confirmButtonText: 'Close',
                    allowOutsideClick: false
                }).then(() => {
                    return history.goBack();
                });
            };
        };
    };

    saveOrUpdateRecord = async () => {
        try {
            this.setState({ saveInprogress: true });
            const { user: { _id, location, isAdmin: isApproved } } = this.props;
            const { currentStep, taskId, formData, record_for, record_name, form: { name, id, question_blocks }, incompleteRecord, completedStep, filesFormData } = this.state;
            const recordData = { name: record_name ? record_name : name, record_for: record_for ? record_for : _id, formData, formJson: this.state.form, currentStep: currentStep >= completedStep ? currentStep : completedStep - 1, formId: id, isCompleted: question_blocks.length === currentStep ? true : false, deleted: false, location: location._id, isApproved };
            if (taskId) recordData.taskId = taskId;
            const apiUrl = incompleteRecord ? `/api/v2/record/${incompleteRecord}` : '/api/v2/record';

            let result;
            filesFormData.delete('recordData');
            filesFormData.append('recordData', JSON.stringify(recordData));
            result = await axios.post(apiUrl, filesFormData);

            this.setState({ filesFormData: new FormData(), saveInprogress: false });
            return result;
        } catch (error) {
            this.setState({ saveInprogress: false, errors: { apiError: true } });
            console.error(error);
            return false;
        }
    };

    onChangeOfQuestion = () => {
        this.setState({
            formData: {
                ...this.state.formData,
            }
        });
        this.setState({
            form: {
                ...this.state.form,
            }
        });
        console.log(formData);
    };
    render() {
        const { history, user } = this.props;
        const { errors, formId, members, equipments, loading, currentStep, completedStep, formData, form: { name: formName, description: formDescription, sections, question_blocks }, errors: { dataChangedError, apiError }, saveInprogress, validated } = this.state;
        const { question_block } = !loading && question_blocks[currentStep - 1] ? question_blocks[currentStep - 1] : [];
        return (
            <>{loading ?
                <Container className='text-center my-3'>
                    <Spinner animation="border" variant="success" />
                </Container> :
                <FormViewContainer>
                    <div className="d-flex justify-content-between mx-2">
                        <div>
                            <h2 className='my-0'>{formName}</h2>
                            <p className='my-0'>{formDescription}</p>
                        </div>
                        <WhiteButton type='button' onClick={() => { clearTimeout(this.timeoutId); history.goBack(); }} width='100' className='my-0'>
                            Cancel
                        </WhiteButton>
                    </div>
                    <hr />
                    {dataChangedError &&
                        <Row>
                            <Col>
                                <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                    <p className='w-100 mb-0'>Save current changes.</p>
                                </Alert>
                            </Col>
                        </Row>
                    }
                    <DetailsSection className='mt-2'>
                        <SubSectionContainer lg='4'>
                            {sections.map(({ label, stepNumber }) => (
                                <SubSectionCard onClick={() => this.changeStep(stepNumber)} active={stepNumber === currentStep ? 'true' : ''} key={stepNumber} completed={stepNumber < completedStep} disabled={stepNumber > completedStep}>
                                    <SubSectionCardBody>
                                        <Col lg='1' className='p-0'>
                                            <ImageContainer src={stepNumber < completedStep ? completed : stepNumber === currentStep ? active : pending} />
                                        </Col>
                                        <Col lg='10' sm='10' className='p-0'>
                                            <Label textColor={stepNumber < completedStep ? '#343747' : stepNumber === currentStep ? '#4FB973' : '#BFBFBF'}>
                                                {stepNumber + ". " + label}
                                            </Label>
                                        </Col>
                                        <Col lg='1' className='p-0'>
                                            {stepNumber === currentStep &&
                                                <ImageContainer src={chevronRight} alt='' />
                                            }
                                        </Col>
                                    </SubSectionCardBody>
                                </SubSectionCard>
                            ))}
                        </SubSectionContainer>

                        {formId === 'uploadHealthDocumentForm' ?
                            <UploadDocuments history={history} user={user} error={errors} members={members} />
                            :
                            <Form noValidate validated={validated} onSubmit={(e) => {
                                if (question_blocks.length === currentStep) {
                                    // if (formName === FORM_NAMES.SLIP_TRIP_FALL) {
                                    //     this.handleOpenForm(e);
                                    // } else {
                                    //     this.save(e);
                                    // }
                                    this.save(e);
                                } else {
                                    this.update(e);
                                }
                            }} className='col-lg-8'>
                                <QuestionSectionContainer>
                                    {question_block && question_block.map((question, index) => (
                                        <Col md='12' className="mb-3" key={index}>
                                            <FormGenerator
                                                members={members}
                                                equipments={equipments}
                                                questionNo={index + 1}
                                                data={formData}
                                                question={question.question}
                                                onFormDataChange={this.onModalChange}
                                                onFilesChange={this.onFilesChange}
                                                onChangeOfQuestion={this.onChangeOfQuestion}
                                            />
                                        </Col>
                                    ))}
                                </QuestionSectionContainer>
                                {apiError &&
                                    <Col lg='12' md='12' sm='12' className='mb-2'>
                                        <Alert id='error-alert' className='mt-2 mb-0' style={{ 'maxWidth': '100%' }} variant="danger">
                                            <p className='w-100 mb-0'>
                                                Unexpected Error. An unexpected error has occurred. Please try again. If the problem persists, please contact Ocoord support.
                                            </p>
                                        </Alert>
                                    </Col>
                                }
                                <FooterButtonSection>
                                    <Col lg='5' md='8' sm='12' className='d-flex justify-content-end'>
                                        {question_blocks.length > 1 &&
                                            <WhiteButton disabled={currentStep === 1} onClick={() => this.setState({ currentStep: currentStep - 1 })} className='my-0 mr-2'>
                                                {'<< Back'}
                                            </WhiteButton>}
                                        <Button
                                            disabled={saveInprogress}
                                            type="submit"
                                            className="my-0 col-lg-6"
                                        >
                                            {saveInprogress
                                                ? "Saving..."
                                                : (question_blocks.length === currentStep
                                                    ? "Save"
                                                    : "Next >>"
                                                )
                                            }
                                        </Button>
                                    </Col>
                                </FooterButtonSection>
                            </Form>
                        }
                    </DetailsSection>

                </FormViewContainer>
            }
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(FormView);